@import '../common/variables';
@import '../common/fonts';

footer {
	padding: 0 $content-margin $content-margin * 4;
	font-size: .75rem;
	color: hsla(0deg, 0%, 50%, 100%);
	background: black;

	p {
		margin-bottom: 1em;
	}
}

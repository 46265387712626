@import 'variables';
@import 'fonts';


html {
	@include flexibleFontSize(16px, .9vmax, 36px, 14px); // min, preferred, max, fallback
	font-family: $base-font-family;
	line-height: $base-font-line-height;
	scroll-behavior: smooth;
	background: #{'hsl(var(--dynamic-background-color-hsl))'};
}

body, main {
	max-width: 100% !important;
	margin: 0;
	background: #{'hsl(var(--dynamic-background-color-hsl))'};
	color: #{'hsl(var(--dynamic-text-color-hsl))'};
	transition: $backgroundTransition;
}

main {
	scroll-snap-type: y mandatory;
}

h1 {
	margin: 0 $content-margin;
	@include headlineFont($weight: bold);
	@include flexibleFontSize(36px, 3vmax, 10vmin); // min, preferred, max, fallback
	line-height: 1;
	letter-spacing: -.025em;
}

h2 {
	margin: 0 0 1em 0;
	@include additionalFont(1.5rem, bold);
}

h3 {
	font-weight: 900;
}

p, ul, ol {
	margin-bottom: 2rem;
}

:is(p, li) strong {
	//@include baseFont(inherit, bold);
	font-weight: bold;
}

a {
	@include baseFont(inherit, inherit);
	color: var(--text-color);
}

::selection {
	color: white;
	background: black;
}

ul {
	list-style-type: none;
}

:is(article) li {
	margin-bottom: .75em;
	padding-left: 2em;
	background: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjIiIGhlaWdodD0iNSIgdmlld0JveD0iMCAwIDIyIDUiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+CjxwYXRoIGZpbGwtcnVsZT0iZXZlbm9kZCIgY2xpcC1ydWxlPSJldmVub2RkIiBkPSJNMC42MDkyNTMgMS42NzgxNUM4LjA1NzUzIDcuMjg4NjMgMTMuMTE1IDAuNTc1OTgzIDIxLjIwNyAzLjg4NjMzQzkuNDM2ODUgLTQuNTc0NzIgNy45NjY4NiA1LjE3MjQxIDAuNjA5MjUzIDEuNjc4MTVaIiBmaWxsPSJibGFjayIvPgo8L3N2Zz4K)
		0 .66em no-repeat;
	background-size: 1.25em auto;
}

.center {
	text-align: center;
}
@import '../../common/variables';
@import '../../common/helpers';
@import '../../common/fonts';

.chapter--light-at-the-end {
	--background-color-hsl: var(--hsl--chapter--light-at-the-end);

	color: white;

	.chapter__content {
		mix-blend-mode: lighten;
		padding-top: 100vh;

		.chapter-headline {
			z-index: 10;
			font-family: $base-font-family;

			.milestone-year {
				color: black;
			}
		}

		article {

			.text {
				z-index: 10;
			}

			aside img {
				transform: scale(1.75);
				transform-origin: 33% 66%;
			}
		}
	}
}

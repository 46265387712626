@import '../common/variables';
@import '../common/fonts';

.h-div {
	display: inline-block;
	width: 7rem; height: 1.25rem;
	margin: 1.25rem 0;
	background: transparent url('../images/ornament-vertical-div-01.svg') 50% 0 no-repeat;
	background-size: contain;

	&.white {
		background-image: url('../images/ornament-vertical-div-01-white.svg');
	}

	&.type-2 {
		background-image: url('../images/ornament-vertical-div-02.svg');

		&.white {
			background-image: url('../images/ornament-vertical-div-02-white.svg');
		}
	}

	&.no-margin {
		margin: 0;
	}

	&.double-margin {
		margin: 2.5rem 0;
	}
}

// for dev
.__console {
	position: fixed;
	top: .5rem;
	left: .5rem;
	width: 40rem;
	height: 10rem;
	z-index: 100000;
	padding: .25rem .5rem;
	font-size: .85rem;
	color: white;
	background: red;
}

@import '../../common/variables';
@import '../../common/helpers';
@import '../../common/fonts';

.chapter--intro {
	--background-color-hsl: var(--hsl--chapter--intro);

	.chapter__content {
		mix-blend-mode: multiply;
		padding: 0;

		@media only screen and (min-width: 60rem) {
			display: grid;
			grid-template-columns: 1fr 1fr 1fr minmax(20rem, 2fr);
			grid-template-rows: 55% 45%;
			grid-template-areas: 'brand claim claim claim' 'painting painting painting about';
			height: 100vh;

			.chapter--intro__brand {
				grid-row: 1;
				grid-column: 1 / span 2;
			}

			.chapter--intro__claim {
				grid-area: claim;
				align-self: end;
				margin-right: $content-margin * 2.5;
				padding-left: .02em; padding-right: .02em;
				@include flexibleFontSize(1rem, 7vmax, 14rem);
				text-align: right;

				.lang-de & {
					@include flexibleFontSize(1rem, 6vmax, 14rem);
				}
			}

			.chapter--intro__painting {
				grid-area: painting;
				align-self: end;
				justify-self: start;
				width: auto;

				img {
					width: auto;
					max-width: 75vw;
					max-height: 72vh;
				}
			}

			.chapter--intro__about {
				grid-area: about;
				align-self: end;
				padding-bottom: $content-margin * 1.5;

				&__content {
					margin-top: 0;
				}
			}
		}
	}

	&__brand {
		z-index: 5;
		padding-top: $content-margin;
		padding-left: $content-margin * 1.25;

		.logo img {
			width: clamp(6rem, 15vw, 12rem);
		}

		.tagline {
			display: block;
			width: clamp(14rem, 20vw, 18rem);
			height: 0;
			max-width: 100vw;
			margin-top: 1.5rem;
			padding-top: 25%;
			overflow: hidden;
			white-space: nowrap;
			background: transparent url('@/images/tagline-legende-aus-dem-sudetenland.svg') 0 0 no-repeat;
			background-size: contain;
		}
	}

	&__claim {
		z-index: 1;
		margin-left: 0; margin-right: 0;
		padding: .25em $content-margin;
		@include baseFont(inherit, bold);
		@include flexibleFontSize(1rem, 9vw, 14rem);
		line-height: 1;
		text-align: center;
		hyphens: auto;
		cursor: default;

		.lang-de & {
			@include flexibleFontSize(1rem, 7vw, 14rem);
		}

		a {
			display: inline-block;
			border-bottom: .066em solid black;
		}
	}

	&__painting {
		width: 120vw;
		background: transparent url('@/images/dreckschanke-illustration-bg.jpg') 0 0 no-repeat;
		background-size: contain;
		overflow: hidden;

		img {
			position: relative;
			z-index: 2;

			@media only screen and (min-width: 60rem) and (max-aspect-ratio: 7/4) {
				@include scrimGradient(black, to right, (80%, 95%), $property: mask-image);
			}
		}
	}

	&__about {
		width: 100%;
		z-index: 4;

		&__content {
			max-width: 18rem;
			margin: 3rem auto 0 auto;
			text-align: center;
		}
	}
}

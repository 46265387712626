
@mixin noScrollbar {

	// Chrome, Safari and Opera
	&::-webkit-scrollbar {
		display: none;
	}

	// IE and Edge
	-ms-overflow-style: none;

	// Firefox
	// noinspection CssUnknownProperty
	scrollbar-width: none;
}

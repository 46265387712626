@import '../../common/variables';
@import '../../common/helpers';
@import '../../common/fonts';

.chapter--new-owner {
	--background-color-hsl: var(--hsl--chapter--new-owner);

	.chapter__content {
		//@include scrimGradient(hsl(202deg, 20%, 59%), to top, (0%, 33%));
		box-shadow: inset 0 -1px 1px rgba(0, 0, 0, .15);
	}

	.sticky-bg-photo {

		.photo-wrapper {
			display: flex;
			justify-content: flex-end;
			height: 100vh;

			@media only screen and (max-width: 42rem) {
				opacity: .33;
			}

			.signature {
				position: absolute;
				top: 35%; right: 3vw;
				@include handwrittenFont();
				@include flexibleFontSize(1rem, 3.5vh, 6rem, 1rem);
				color: rgba(0, 0, 0, .25);
			}

			picture {
				width: auto;
				height: 75%;
				margin: auto 10vw 0 0;

				img {
					object-fit: contain;
					object-position: 100% 100%;
				}
			}
		}
	}

	.chapter-headline {
		grid-column: 1 / span 2;
		font-family: $base-font-family;
	}

	article .text {
		padding-bottom: 4rem;
	}
}
